import ManagementTeam from "../../components/AboutUs/ManagementTeam";
import BannerInner from "../../components/Banner/BannerInner";
import HomePageForm from "../../components/Home/HomePageForm";
import Cta from "../../components/Cta/Cta";
import ManagementTeamDesign1 from "../../components/AboutUs/ManagementTeamDesign1";
import { ISO27001, ISO9001 } from '../../assets/img'
function ContactUs() {
    const aboutBannerData = {
        heading: "About Us"
    }
    return (
        <>
            <BannerInner
                bannerData={aboutBannerData}
            />
            {/* <HmgAboutus /> */}
            <section className="pdtb pb-0 abutus-page">
                <div className="container">
                    {/* <p>
            <strong>StatWorld</strong> is a full service analytics solutions provider that offers expert services in
            the field of advanced marketing analytics and market research. We offer end-to-end data driven solutions
            to global clients, using customized analytics, modeling and market research.
          </p>
          <p>We offer a range of services that cover all aspects of project. Our key service offerings are:</p>
          <div className="row">
            <div className="col-lg-6">
              <h3>MARKET RESEARCH</h3>
              <ul>
                <li>Research Design</li>
                <li>Survey Programming & Hosting</li>
                <li>Data Collection & Field Management</li>
                <li>Analysis & Reporting</li>
              </ul>
              <h3>OTHER RESEARCH</h3>
              <ul>
                <li>Dashboards & Performance Reporting</li>
                <li>Social Media Analytics</li>
                <li>Excel Based Automation</li>
                <li>Industry Research</li>
                <li>Company/Competitive Intelligence</li>
                <li>Desk Research</li>
              </ul>
            </div>
            <div className="col-lg-6">
              <h3>ADVANCED ANALYTICS</h3>
              <ul>
                <li>Discrete Choice Modeling (Conjoint Research)</li>
                <li>Market Segmentation</li>
                <li>Pricing Research</li>
                <li>Marketing & Sales Effectiveness</li>
                <li>CRM Analytics</li>
                <li>Market Assessment & Commercialization</li>
                <li>Financial Modeling</li>
                <li>Predictive Modeling</li>
              </ul>
              <h3>BI & DATA VISUALIZATION</h3>
              <ul>
                <li>Smart Interactive Dashboards</li>
                <li>Periodic Reporting</li>
              </ul>
            </div>
          </div>
          <p className="mb-0">
            We work across multiple sectors such as Healthcare, Pharmaceuticals, Retail & Consumer Goods,
            Banking & Finance, Food & Beverage, IT & Telecom, Automotive, Logistics & Supply Chain.
          </p> */}

                    <div className="our-intro-head">
                        <h2 className="text-uppercase">  Data-Driven <span>Insights</span>, Impactful Decisions</h2>
                    </div>
                    <p>
                        Welcome to STATWORLD ANALYTICS, your trusted partner in the realm of Quantitative Research, Analytics,
                        and Data Intelligence. Our primary landscape not only encompasses Customized Analytics, Comprehensive
                        Market Research, Social Media Analytics, Data Visualization, and ETL Solutions, but  also various related components.
                    </p>
                    <p>
                        We are driven by a passion for innovation, a dedication to precision, and a commitment to transforming data into
                        actionable intelligence. What sets us apart is our relentless dedication to staying at the forefront of industry
                        trends and emerging technologies. We leverage the latest advancements in data analytics, machine learning, and artificial
                        intelligence to uncover deeper insights and deliver strategic recommendations that are both actionable and forward-thinking.
                    </p>
                    <p>
                        Our team of seasoned professionals boasts a diverse range of expertise, ensuring that we can meet your specific needs and
                        objectives with precision. We combine the best of industry knowledge, cutting-edge technology, and an unwavering commitment
                        to excellence to provide you with tailored solutions that drive tangible results.
                    </p>
                    <p>
                        We take pride in our ability to work seamlessly with clients from various industries, from startups to Fortune 500 companies.
                        Our client roster includes some of the most prestigious names in business, a testament to our commitment to delivering exceptional value.
                    </p>
                    <p>
                        We understand that every business is unique, and we take a consultative approach to create customized solutions that align
                        with your goals and challenges. Whether you are seeking market intelligence, advanced analytics, or a tailored research strategy,
                        StatWorld Analytics is your one-stop solution provider. We are here to transform your challenges into opportunities, helping you
                        navigate the complexities of your industry with confidence. Get to know us better and discover how we can empower your business to
                        thrive in today’s competitive landscape. Reach out to us today!
                    </p>
                </div>
            </section>

            <section className="certification-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6">
                            <div className="certification-card">
                                <div className="cc-img">
                                    <img src={ISO27001} className='img-fluid' alt="ISO 27001" />
                                </div>
                                <div className="cc-content">
                                    <h4> ISO 9001:2015 Certified </h4>
                                    <p> Ensures our commitment to quality management, efficiency, and continuous improvement, delivering exceptional services that meet global standards.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="certification-card">
                                <div className="cc-img">
                                <img src={ISO9001} className='img-fluid' alt="ISO 9001" />
                                </div>
                                <div className="cc-content">
                                    <h4> ISO 27001:2022 Certified </h4>
                                    <p>  Demonstrates our dedication to information security, safeguarding your data with robust security measures and compliance with international best practices. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Cta/> */}
            {/* <ManagementTeam/> */}
            <ManagementTeamDesign1 />
            <HomePageForm />
        </>
    );
}

export default ContactUs;
