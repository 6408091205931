import styles from './../../assets/css/footer-theme.module.scss'
import { Link } from "react-router-dom";
import { LogoMain, ISO27001, ISO9001 } from '../../assets/img'

const Footer = () => {
    const handleMove = (id) => {
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                const headerOffset = 200;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }, 100);
    };
    return (
        <footer className={`${styles.theme_ftr}`}>
            <div className="container">
                <div className="row">
                    <div className={`${styles.theme_ftr_innr} col-lg-3 `}>
                        <img src={LogoMain} alt="" />
                        <p>102 Abbe Road, Little Falls, <br />NJ, 07424, United States</p>
                        <p>C-602, Sarve Satyam CGHS Ltd.,<br />Plot No. 12, Sector – 4 <br />Dwarka, New Delhi-110078, India</p>
                    </div>
                    <div className={`${styles.theme_ftr_innr} col-lg-3 col-md-4 col-sm-6`}>
                        <h4>About</h4>
                        <ul>
                            <li>
                                <Link to="/about-us">About Us</Link>
                            </li>
                            <li>
                                <Link to="/about-us" onClick={() => handleMove("managementTeam")}>Management Team</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={`${styles.theme_ftr_innr} col-lg-3 col-md-4 col-sm-6`}>
                        <h4>Services</h4>
                        <ul>
                            <li>
                                <Link to="/market-research">Market Research</Link>
                            </li>
                            <li>
                                <Link to="/advanced-analytics">Advanced Analytics</Link>
                            </li>
                            <li>
                                <Link to="/social-media-analytics">Social Media Analytics</Link>
                            </li>
                            <li>
                                <Link to="/bi-data-visualization">BI & Data Visualization</Link>
                            </li>
                            <li>
                                <Link to="/etl-implementation">ETL Implementation</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={`${styles.theme_ftr_innr} col-lg-3 col-md-4 col-sm-6`}>
                        <h4>Others</h4>
                        <ul>
                            <li>
                                <Link to="#">Careers</Link>
                            </li>
                            <li>
                                <Link to="#">Media Coverage</Link>
                            </li>
                            <li>
                                <Link to="/contact-us">Contact Us</Link>
                            </li>
                        </ul>

                        <div className={styles.branding_logo}>
                            <img src={ISO27001} className='img-fluid' alt="ISO 27001" />
                            <img src={ISO9001} className='img-fluid' alt="ISO 9001" />
                        </div>
                    </div>
                </div>
                <p className={`${styles.ftr_text} text-center`}>© Copyright {new Date().getFullYear()}. All Rights Reserved. | Powered By <Link to="https://scrumdigital.com/">Scrum Digital</Link></p>
            </div>
        </footer>
    );
};

export default Footer;
